//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components:{
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue'),
        MyFriends: () => import('@/components/User/MyFriends.vue'),
    },
    metaInfo(){
        return {
            title:'Friends'
        }
    },
    data(){
        return {
            first_name: this.$store.state.user.first_name,
            currentUserId: this.$store.state.user.id
        }
    }
}
